import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { APIEndpoint } from '../appsettings/BaseUrl';

@Injectable()
export class SectionService {
  
  private BASE_URL: string;
  _headers:any;
  constructor(private http:HttpClient, private _router:Router) {
    this.BASE_URL = APIEndpoint.BaseUrl;
    this._headers= new HttpHeaders({
    'Client-Service': 'LRL2018SH',
    'Auth-Key': '5ccd7b534b19d30030c6503f3a852d00',
    'Content-Type': 'application/json'
  })
  }
  AddSection(data):Observable<any>{    
    const url = this.BASE_URL + 'add-section';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  }

  UpdateSection(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-section';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  DeleteSection(data):Observable<any>
  {
    const url = this.BASE_URL + 'delete-section';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  GetSectionList():Observable<any>
  {    
    const url = this.BASE_URL + 'section-list';
    return this.http.post(url,{},{headers:this._headers}).map(res=>res);
  }  
   SearchSection(data):Observable<any>
  {    
    const url = this.BASE_URL + 'search-section';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  }

}
