import { Component, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ROUTES } from './menu-items';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import {EncryptionService} from '../../services/encryption.service';
import {DataService } from '../../services/data.service';
declare var $: any;
@Component({
  selector: 'ap-sidebar',
  templateUrl: './sidebar.component.html'
  
})
export class SidebarComponent implements OnInit {
    
    
    showMenu: string = '';
    showSubMenu: string = '';
    public sidebarnavItems: any[];
    //this is for the open close
    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
            
        } else {
            this.showMenu = element; 
        }
    }
    addActiveClass(element: any) {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
         $("body").trigger("resize");
         $("#main-wrapper").addClass("mini-sidebar");
         }        
        if(element === this.showSubMenu) {
          this.showSubMenu = '0';            
        } else {
           this.showSubMenu = element; 
        }
    }
   constructor(private modalService: NgbModal, private router: Router,
        private route: ActivatedRoute,private EncryptionService:EncryptionService,private DataService:DataService) {     
     this.DataService.getUserState().subscribe(data=>{
      this.users=data.users;
      this.updateuserdata();
    }) 
        
    } 
    // End open close
    SessionYear="";
    users:any;
    role_id:any;
    user_id:any;    
    staff_email:any;    
    staff_fullname:any;    
    ngOnInit() {
   
if(!(localStorage.getItem('db_name')==null || localStorage.getItem('db_name')=="undefined")){
       this.SessionYear=this.EncryptionService.DecryptText(localStorage.getItem('db_name')).replace("_","-");
   }
    let user_id=localStorage.getItem('user_id') || '';
    let role_id=localStorage.getItem('role_id') || '';
    let staff_firstname=localStorage.getItem('staff_firstname') || '' ;
    let staff_lastname=localStorage.getItem('staff_lastname') || '';
    let staff_email=localStorage.getItem('staff_email') || '';
    if(user_id!=''){
    this.users={user_id:user_id,role_id:role_id,staff_firstname:staff_firstname,staff_lastname:staff_lastname,staff_email:staff_email};
    this.updateuserdata();
    }

    if(this.role_id==3){
       this.sidebarnavItems=ROUTES.filter(res=>(res.title=="Student" || res.title=="Notifications Center"));
       this.sidebarnavItems[0].submenu.splice(0,1);
       }else if(this.role_id==2){
       this.sidebarnavItems=ROUTES.filter(res=>res.title=="Fees");
       this.sidebarnavItems[0].submenu.splice(0,2);
       }else{
        this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
         }
        $(function () {
            $(".sidebartoggler").on('click', function() {
                if ($("#main-wrapper").hasClass("mini-sidebar")) {
                    $("body").trigger("resize");
                    $("#main-wrapper").removeClass("mini-sidebar");
                     
                } else {
                    $("body").trigger("resize");
                    $("#main-wrapper").addClass("mini-sidebar");
                }
            });

        });
        
    }

    updateuserdata()
    {
        this.role_id=this.EncryptionService.DecryptText(this.users.role_id);
        this.user_id=this.EncryptionService.DecryptText(this.users.user_id);
        this.staff_email=this.EncryptionService.DecryptText(this.users.staff_email);
        this.staff_fullname=this.EncryptionService.DecryptText(this.users.staff_firstname)+" "+this.EncryptionService.DecryptText(this.users.staff_lastname);
    }


}
