import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { APIEndpoint } from '../appsettings/BaseUrl';
@Injectable()
export class UploadService {
  
  private BASE_URL: string;
  _headers:any;
  constructor(private http:HttpClient) {
    this.BASE_URL = APIEndpoint.BaseUrl;
    this._headers= new HttpHeaders({
    'Client-Service': 'LRL2018SH',
    'Auth-Key': '5ccd7b534b19d30030c6503f3a852d00',
    'enctype':'multipart/form-data'
  })
  }


  AddStudent(data):Observable<any>
  {
    const url = this.BASE_URL + 'add-student-profile';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  AddEvent(data):Observable<any>
  {
    const url = this.BASE_URL + 'add-gallery';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    }); 
  }
   UpdateImageGallery(data):Observable<any>
  {
    const url = this.BASE_URL + 'edit-gallery';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    }); 
  }
  UpdateStudentProfile(data):Observable<any>
  {
     const url = this.BASE_URL + 'student-profile-update';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  AddAchievement(data):Observable<any>
  {
     const url = this.BASE_URL + 'add-achievement';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  UpdateAchievement(data):Observable<any>
  {
     const url = this.BASE_URL + 'update-achievement';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  
  AddCircular(data):Observable<any>
  {
    const url = this.BASE_URL + 'add-circular';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  AddHomework(data):Observable<any>
  {
    const url = this.BASE_URL + 'add-homework';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

   SendNotification(data):Observable<any>
  {
    const url = this.BASE_URL + 'send-notification';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }



}