import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { APIEndpoint } from '../appsettings/BaseUrl';

@Injectable()
export class BusService {

  private BASE_URL: string;
  _headers:any;
  constructor(private http:HttpClient, private _router:Router) {
    this.BASE_URL = APIEndpoint.BaseUrl;
    this._headers= new HttpHeaders({
    'Client-Service': 'LRL2018SH',
    'Auth-Key': '5ccd7b534b19d30030c6503f3a852d00',
    'Content-Type': 'application/json'
  })
  }

  AddBus(data):Observable<any>{    
    const url = this.BASE_URL + 'add-bus';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  GetBusList():Observable<any>
  {
     const url = this.BASE_URL + 'bus-list';
    return this.http.post(url,{},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    }); 
  }
  DeleteBus(data):Observable<any>
  {
     const url = this.BASE_URL + 'delete-bus';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    }); 
  }
  UpdateBus(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-bus';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });   
  }
  SearchBus(id):Observable<any>
  {
    const url = this.BASE_URL + 'search-bus';
    return this.http.post(url,{id:id},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });   
  }
  SearchBusRoute(id):Observable<any>
  {
    const url = this.BASE_URL + 'search-bus-route';
    return this.http.post(url,{bus_id:id},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });   
  }

  AddBusRoute(data):Observable<any>
  {
    const url = this.BASE_URL + 'add-bus-route';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });   

  }

  UpdateBusRoute(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-bus-route';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });   
  }
   DeleteBusRoute(data):Observable<any>
  {
    const url = this.BASE_URL + 'delete-bus-route';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });   
  }

  AssignBus(data):Observable<any>
  {
    const url = this.BASE_URL + 'add-student-bus';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });   
  }
  SearchBusStudent(data):Observable<any>
  {
    const url = this.BASE_URL + 'search-student-bus';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });   
  }
  UnassignBus(data):Observable<any>
  {
    const url = this.BASE_URL + 'delete-student-bus';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });   
  }


}
