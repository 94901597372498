import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs/BehaviorSubject"

@Injectable()
export class DataService {
  private userstate$ = new BehaviorSubject<any>({users:{user_id:null,role_id:null,staff_firstname:null,staff_lastname:null,staff_email:null}});
  userstate=this.userstate$.asObservable();
  constructor() { 
  }
  changeUserState(myChange) {this.userstate$.next(myChange);}
  getUserState() {return this.userstate$.asObservable();}

}
