import { Injectable,Inject } from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class LoaderService {
	
	constructor(@Inject(DOCUMENT) private document: Document) {
		
	}

	isLoading(value)
	{
		if(value)
		{
			var loader=this.document.querySelector('.preloader');
			
			loader.classList.remove('hideLoader');
			loader.classList.add('showLoader');
		}
		else{
			var loader=this.document.querySelector('.preloader');
			loader.classList.remove('showLoader');
			loader.classList.add('hideLoader');
		}
	}
}