import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import {HttpRequest,HttpHandler,HttpEvent,HttpClient, HttpHeaders,HttpInterceptor,HttpResponse } from '@angular/common/http';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { APIEndpoint } from '../appsettings/BaseUrl';
 import {LoaderService} from './loader.service';
 import {EncryptionService} from './encryption.service';


@Injectable()
export class MyInterceptorService implements HttpInterceptor {
  constructor(private LoaderService:LoaderService,private EncryptionService:EncryptionService){}
   intercept(     
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if(req.url.split('/')[req.url.split('/').length-1]!="fee-detail-by-student" && req.url.split('/')[req.url.split('/').length-1]!="update-student-fee")
    {
    this.LoaderService.isLoading(true);
    }
   // req.body.append("db_name","1111");
   
   if(!(localStorage.getItem('db_name')==null || localStorage.getItem('db_name')=="undefined"))
   req.body.db_name=this.EncryptionService.DecryptText(localStorage.getItem('db_name'));
   // const duplicate = req.clone();
   return next.handle(req).map((event: HttpEvent<any>) => {
  if (event instanceof HttpResponse) {
    
    this.LoaderService.isLoading(false);
    // event.headers
    // event.body
  }
  return event;
}).catch((error, caught) => {
    this.LoaderService.isLoading(false);
      return Observable.throw(error);
   }) as any;
  }
}
