import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';

import { BlankComponent } from './layouts/blank/blank.component';
import { AuthGuardService as AuthGuard } from './services/authGuard.service';
import {AnonymousGuardService as LoginGuard} from './services/loginguard.service';

export const routes: Routes = [
{
    path: '',
    component: FullComponent,
    children: [
        // { path: '', redirectTo: '/dashboard/dashboard1', pathMatch: 'full' },
        
        { path: 'dashboard', loadChildren: './dashboards/dashboard.module#DashboardModule',canActivate:[AuthGuard]},
        { path: 'promote-student', loadChildren: './promotestudent/promotestudent.module#PromotestudentModule',canActivate:[AuthGuard]},
        { path: 'school', loadChildren: './school/school.module#SchoolModule',canActivate:[AuthGuard]},
        {path: 'staff',loadChildren: './staff/staff.module#StaffModule',canActivateChild:[AuthGuard]},
        {path: 'fees',loadChildren: './fees/fees.module#FeesModule',canActivateChild:[AuthGuard]},
        {path: 'staffrole',loadChildren: './staffrole/staffrole.module#StaffroleModule',canActivateChild:[AuthGuard]},
        {path: 'student',loadChildren: './student/student.module#StudentModule',canActivateChild:[AuthGuard]},
        {path: 'classes',loadChildren: './classes/classes.module#ClassesModule',canActivateChild:[AuthGuard]},
        {path: 'subjects',loadChildren: './subjects/subjects.module#SubjectsModule',canActivateChild:[AuthGuard]},
        {path: 'sections',loadChildren: './sections/sections.module#SectionsModule',canActivateChild:[AuthGuard]},
        {path: 'events',loadChildren: './events/events.module#EventsModule',canActivateChild:[AuthGuard]},
        {path: 'notifications',loadChildren: './notification/notification.module#NotificationModule',canActivateChild:[AuthGuard]},
        {path: 'teacher',loadChildren: './teachers/teachers.module#TeachersModule',canActivateChild:[AuthGuard]},
        {path: 'achievement',loadChildren: './achievement/achievement.module#AchievementModule',canActivateChild:[AuthGuard]},
        {path: 'exam',loadChildren: './exam/exam.module#ExamModule',canActivateChild:[AuthGuard]},
        {path: 'gallery',loadChildren: './gallery/gallery.module#GalleryModule',canActivateChild:[AuthGuard]},
        {path: 'bus-allocation',loadChildren: './busallocation/busallocation.module#BusallocationModule',canActivateChild:[AuthGuard]},
        {path: 'imports',loadChildren: './imports/imports.module#ImportsModule',canActivateChild:[AuthGuard]},
        { path: '', redirectTo: '/dashboard', pathMatch: 'full'},
      
        
    ]
},
{
    path: '',
    component: BlankComponent,
    children: [
        {
            path: 'authentication',
            loadChildren: './authentication/authentication.module#AuthenticationModule'
        }
    ]
}, 

{
    path: '**',
    redirectTo: '404' 
}];

@NgModule({
    imports: [RouterModule.forRoot(routes), NgbModule.forRoot()],
    exports: [RouterModule]
})
export class AppRoutingModule { }

