import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  // {
  //     path: '', title: 'Personal', icon: '', class: 'nav-small-cap', label: '', labelClass: '', extralink: true, submenu: []
  // },

  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    label: '',
    labelClass: 'label label-rouded label-themecolor pull-right',
    extralink: false,
    submenu: [
    
      
    ]
  },
  //  {
  //   path: '/promote-student',
  //   title: 'Promote Student',
  //   icon: 'fa fa-group',
  //   class: '',
  //   label: '',
  //   labelClass: 'label label-rouded label-themecolor pull-right',
  //   extralink: false,
  //   submenu: [
    
      
  //   ]
  // },


  // {
  //   path: '/staffrole',
  //   title: 'Staff Role list',
  //   icon: 'mdi mdi-format-list-bulleted',
  //   class: '',
  //   label: '',
  //   labelClass: '',
  //   extralink: false,
  //   submenu: []

  // },

  // {
  //   path: '/staff',
  //   title: 'Staff list',
  //   icon: 'fa fa-group',
  //   class: '',
  //   label: '',
  //   labelClass: '',
  //   extralink: false,
  //   submenu: []

  // },
  {
    path: '',
    title: 'School',
    icon: 'mdi mdi-library-books',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [{
        path: '/school/houses',
        title: 'Houses',
        icon: '',
        class: 'mdi mdi-chevron-right',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/school/school-remarks',
        title: 'School Remarks',
        icon: '',
        class: 'mdi mdi-chevron-right',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      }, {
        path: '/school/result-remarks',
        title: 'Result Remarks',
        icon: '',
        class: 'mdi mdi-chevron-right',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Academic',
    icon: 'mdi mdi-library-books',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [{
        path: '/subjects',
        title: 'Subjects',
        icon: '',
        class: 'mdi mdi-chevron-right',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/sections',
        title: 'Sections',
        icon: '',
        class: 'mdi mdi-chevron-right',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/classes',
        title: 'Classes',
        icon: '',
        class: 'mdi mdi-chevron-right',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []

      },
       {
        path: '/teacher',
        title: 'Teacher Allocation',
        icon: '',
        class: 'mdi mdi-chevron-right',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []

      },
      {
        path: '/teacher/class-subject-teacher',
        title: 'Subject Teacher',
        icon: '',
        class: 'mdi mdi-chevron-right',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []

      },
      {
        path: '/exam/test-type-list',
        title: 'Test Type',
        icon: '',
        class: 'mdi mdi-chevron-right',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []

      },
      {
        path: '/exam',
        title: 'Examinations',
        icon: '',
        class: 'mdi mdi-chevron-right',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []

      },
       {
        path: '/exam/exam-class-status',
        title: 'Exam Class Status',
        icon: '',
        class: 'mdi mdi-chevron-right',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []

      },
      {
        path: '/exam/exam-schedule-list',
        title: 'Exam Schedule List',
        icon: '',
        class: 'mdi mdi-chevron-right',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []

      },
       {
        path: '/subjects/elective-subject',
        title: 'Elective Subjects',
        icon: '',
        class: 'mdi mdi-chevron-right',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []

      },
      
    ]
  },
  {
    path: '',
    title: 'Fees',
    icon: 'mdi mdi-wallet-membership',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      { path: '/fees/segment-list', title: 'Fees Segment', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      { path: '/fees/fee-schedule-list', title: 'Fees Schedule List', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      { path: '/fees/fee-list', title: 'Fees Collection', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },


    ]

  },


  {
    path: '/student',
    title: 'Student',
    icon: 'fa fa-user',
    class: 'has-arrow',
    label: '',
    labelClass: 'label label-rouded label-themecolor pull-right',
    extralink: false,
    // path: '/student', title: 'Student', icon: 'mdi mdi-widgets', class: 'has-arrow', label: '', labelClass: '1', extralink: false, 
    submenu: [
      { path: '/student/student-list', title: 'Student List', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      { path: '/student/student-attendance', title: 'Attendance', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      { path: '/student/attendance-summary', title: 'Attend Summary', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      { path: '/student/homework-list', title: 'Homework', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      { path: '/student/remarks-list', title: 'Remarks', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      { path: '/student/marks', title: 'Marks', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      { path: '/student/result-list', title: 'Results', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },  
     
    ]
  },
    {
    path: '/bus-allocation',
    title: 'Bus Allocation',
    icon: 'mdi mdi-bus',
    class: 'has-arrow',
    label: '',
    labelClass: 'label label-rouded label-themecolor pull-right',
    extralink: false,
    submenu: [
      { path: '/bus-allocation/bus-list', title: 'Bus List', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      { path: '/bus-allocation/add-bus-route', title: 'Add Bus Route', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      { path: '/bus-allocation/assign-bus', title: 'Assign Bus', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      
    ]
  },
  {
    path: '/gallery',
    title: 'Image Gallery',
    icon: 'mdi mdi-file-image',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []

  },
  {
    path: '/notifications/notification-list',
    title: 'Notifications Center',
    icon: 'mdi mdi-bell',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Calendar Events',
    icon: 'mdi mdi-calendar',
    class: 'has-arrow',
    label: '',
    labelClass: 'label label-rouded label-themecolor pull-right',
    extralink: false,
    submenu: [
      { path: '/events/event-list', title: 'Events', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      
      

    ]
  },
  {
    path: '/achievement',
    title: 'Achievements',
    icon: 'mdi mdi-trophy',
    class: '',
    label: '',
    labelClass: 'label label-rouded label-themecolor pull-right',
    extralink: false,
    submenu: [
      // { path: '/notifications', title: 'Notification', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },

    ]
  },
  // {
  //   path: '/imports',
  //   title: 'Imports',
  //   icon: 'fa fa-user',
  //   class: 'has-arrow',
  //   label: '',
  //   labelClass: 'label label-rouded label-themecolor pull-right',
  //   extralink: false,
  //   // path: '/student', title: 'Student', icon: 'mdi mdi-widgets', class: 'has-arrow', label: '', labelClass: '1', extralink: false, 
  //   submenu: [
  //     { path: '/imports', title: 'Student Import', icon: 'mdi mdi-chevron-right', class: '', label: '', labelClass: '', extralink: false, submenu: [] },
      
  //   ]
  // },



];
