import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

 import{ AuthService } from './services/auth.service';
import { AuthGuardService } from './services/authGuard.service';
import { AnonymousGuardService } from './services/loginguard.service';
import { BusService} from './services/bus.service';
import { ClassesService} from './services/classes.service';
import { DataService } from './services/data.service';
import { EncryptionService} from './services/encryption.service';
import { EventService} from './services/event.service';
import { ExamService} from './services/exam.service';
import { FeesSegmentService} from './services/feesegmant.service';
import { GalleryService} from './services/gallery.service';
import { HouseService } from './services/house.service';
import { LoaderService } from './services/loader.service';
import { MyInterceptorService } from './services/myinterceptor.service';
import { NotificationServiceCustom } from './services/notifications.service';
import { PromoteStudentService } from './services/promotestudent.service';
import { RemarksService } from './services/remarks.service';
import { SectionService } from './services/section.service';
import { StaffService } from './services/staff.service';
import { StudentService } from './services/student.service';
import { SubjectService } from './services/subject.service';
import { TeacherService } from './services/teacher.service';
import { UploadService } from './services/upload.service';

// import { NgxSpinnerModule } from 'ngx-spinner';
// import { NgxSpinnerModule } from "ngx-spinner";



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    
    
  ],
  imports: [
    CommonModule,
    BrowserModule,
    // NgxSpinnerModule,
    BrowserAnimationsModule,   
    FormsModule,
    HttpModule,
    NgbModule.forRoot(),  
    PerfectScrollbarModule,
    AppRoutingModule,
       HttpClientModule
  ],
  providers: [
      {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  },
AuthService,
AuthGuardService,
AnonymousGuardService,
BusService,
ClassesService,
DataService,
EncryptionService,
EventService,
ExamService,
FeesSegmentService,
GalleryService,
HouseService,
LoaderService,
NotificationServiceCustom,
PromoteStudentService,
RemarksService,
SectionService,
StaffService,
StudentService,
SubjectService,
TeacherService,
UploadService,


UploadService,
  {provide: HTTP_INTERCEPTORS, useClass: MyInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
