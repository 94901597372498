// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   // apiUrl: 'http://localhost/nxschool/api/V1/apanel/',
   apiUrl: 'https://devhouse.syscraft-pro.tk/security-app/api/V1/',
   authKey:'security5ccd7b534b19d30030c6503f3a852d00app',
   clientService:'SecurityApp2020',    
  firebase: {
    apiKey: "AIzaSyBOn08tFLXbNWONadawg6VwXj8IOFo9ww4",
    authDomain: "lotuselec-e194e.firebaseapp.com",
    databaseURL: "https://lotuselec-e194e.firebaseio.com",
    projectId: "lotuselec-e194e",
    storageBucket: "lotuselec-e194e.appspot.com",
    messagingSenderId: "319489292194",
    appId: "1:319489292194:web:6a6a8086bbeb6a2be264fb"
  }
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
//fAbz-MMNlWc:APA91bHHKAWEiZbh9UNr8MDyJF8A7LHuoo1SymRkgaKCxwk7BnPhfDUmUi_I-ujuVjYfz8-KxU738dHyoa_j0mHv2q9WnYYjzyTT117xNy6Ihw4Y2R7P7PGJeulInHQC32jQYTnXRQtR

