import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { APIEndpoint } from '../appsettings/BaseUrl';

@Injectable()
export class RemarksService {
  
  private BASE_URL: string;
  _headers:any;
  constructor(private http:HttpClient, private _router:Router) {
    this.BASE_URL = APIEndpoint.BaseUrl;
    this._headers= new HttpHeaders({
    'Client-Service': 'LRL2018SH',
    'Auth-Key': '5ccd7b534b19d30030c6503f3a852d00',
    'Content-Type': 'application/json'
  })
  }
  AddRemarks(data):Observable<any>{    
    const url = this.BASE_URL + 'add-school-remarks';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  }

  UpdateRemarks(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-school-remarks';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  DeleteRemarks(data):Observable<any>
  {
    const url = this.BASE_URL + 'delete-school-remarks';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
    
  }

  GetRemarksList():Observable<any>
  {    
    const url = this.BASE_URL + 'school-remarks-list';
    return this.http.post(url,{},{headers:this._headers}).map(res=>res);
  }  
   SearchRemarks(data):Observable<any>
  {    
    const url = this.BASE_URL + 'search-school-remarks';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  }


  AddResultRemarks(data):Observable<any>{    
    const url = this.BASE_URL + 'add-result-remarks';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  }

  UpdateResultRemarks(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-result-remarks';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  DeleteResultRemarks(data):Observable<any>
  {
    const url = this.BASE_URL + 'delete-result-remarks';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
    
  }

  GetResultRemarksList():Observable<any>
  {    
    const url = this.BASE_URL + 'result-remarks-list';
    return this.http.post(url,{},{headers:this._headers}).map(res=>res);
  }  
   SearchResultRemarks(data):Observable<any>
  {    
    const url = this.BASE_URL + 'search-result-remarks';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  }

}
