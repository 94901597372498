
// Base url of project

export class APIEndpoint{
  //   public static BaseUrl='http://parentappqa.syscraft-pro.tk/V1/apanel/';
	 public static BaseUrl='https://staff.laurelsschool.com/api/V1/apanel/';
 //  public static BaseUrl='http://13.234.171.94/staff/api/V1/apanel/';


	
}

