import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { APIEndpoint } from '../appsettings/BaseUrl';

@Injectable()
export class ExamService {
  private BASE_URL: string;
  _headers:any;
  constructor(private http:HttpClient, private _router:Router) {
    this.BASE_URL = APIEndpoint.BaseUrl;
    this._headers= new HttpHeaders({
    'Client-Service': 'LRL2018SH',
    'Auth-Key': '5ccd7b534b19d30030c6503f3a852d00',
    'Content-Type': 'application/json'
  })
  }
  

  AddExamFunction(data):Observable<any>
  {
    const url = this.BASE_URL + 'add-exam';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  GetExamList(data):Observable<any>
  {
    const url = this.BASE_URL + 'exam-list';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  GetExamScheduleList(data):Observable<any>
  {
    const url = this.BASE_URL + 'exam-schedule';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  SearchExam(id):Observable<any>
  {
    const url = this.BASE_URL + 'search-exam';
    return this.http.post(url,{exam_id:id},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  DeleteExam(id):Observable<any>
  {
    const url = this.BASE_URL + 'delete-exam';
    return this.http.post(url,{exam_id:id},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  UpdateExam(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-exam';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

/*---------------------------------------------*/
 AddTestType(data):Observable<any>
  {
    const url = this.BASE_URL + 'add-test-type';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  GetTestTypeList(data):Observable<any>
  {
    const url = this.BASE_URL + 'test-type-list';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  SearchTestType(id):Observable<any>
  {
    const url = this.BASE_URL + 'search-test-type';
    return this.http.post(url,{test_type_id:id},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  DeleteTestType(id):Observable<any>
  {
    const url = this.BASE_URL + 'delete-test-type';
    return this.http.post(url,{test_type_id:id},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  UpdateTestType(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-test-type';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }


/*---------------------------------------------*/
 AddExamSchedule(data):Observable<any>
  {
    const url = this.BASE_URL + 'add-exams-schedule';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  GetExamScheduleList1(data):Observable<any>
  {
    const url = this.BASE_URL + 'get-exams-schedule';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  SearchExamSchedule(id):Observable<any>
  {
    const url = this.BASE_URL + 'search-exams-schedule';
    return this.http.post(url,{exam_sch_id:id},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  DeleteExamSchedule(id):Observable<any>
  {
    const url = this.BASE_URL + 'delete-exams-schedule';
    return this.http.post(url,{test_type_id:id},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  UpdateExamSchedule(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-exams-schedule';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  ChangeExamClassStatus(data):Observable<any>
  {
    const url = this.BASE_URL + 'change-exam-class-status';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  GetExamClassList(data):Observable<any>
  {
    const url = this.BASE_URL + 'get-exam-class-list';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }


   DownloadResult(data):Observable<any>
  {
    const url = this.BASE_URL + 'admin-download-result';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

}