import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { APIEndpoint } from '../appsettings/BaseUrl';


@Injectable()
export class FeesSegmentService {
  
  private BASE_URL: string;
  _headers:any;
  constructor(private http:HttpClient, private _router:Router) {
    this.BASE_URL = APIEndpoint.BaseUrl;
    this._headers= new HttpHeaders({
    'Client-Service': 'LRL2018SH',
    'Auth-Key': '5ccd7b534b19d30030c6503f3a852d00',
    'Content-Type': 'application/json'
  })


  }

  // AddStaff(data):Observable<any>{    
  //   const url = this.BASE_URL + 'add-staff';
  //   return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  // }

  

  GetFeesSegmant():Observable<any>
  {    
    const url = this.BASE_URL + 'segment-list';
    return this.http.post(url,{},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  GetSegmantList():Observable<any>
  {    
    const url = this.BASE_URL + 'segment-master-list';
    return this.http.post(url,{},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  AddSegment(data):Observable<any>
  {
    const url = this.BASE_URL + 'add-fee-segment';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  UpdateSegment(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-fee-segment';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  DeleteSegment(data):Observable<any>
  {
    const url = this.BASE_URL + 'delete-fee-segment';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  
  GetSegmentById(id):Observable<any>
  {
    const url = this.BASE_URL + 'search-segment';
    return this.http.post(url,{id:id},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  GetFeeScheduleList():Observable<any>
  {
    const url = this.BASE_URL + 'schedule-list';
    return this.http.post(url,{},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  AddFeeSchedule(data):Observable<any>
  {
    const url = this.BASE_URL + 'add-fee-schedule';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  DeleteFeeSchedule(data):Observable<any>
  {
    const url = this.BASE_URL + 'delete-fee-schedule';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  EditFeeSchedule(id):Observable<any>
  {
    const url = this.BASE_URL + 'search-schedule';
    return this.http.post(url,{id:id},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  UpdateFeeSchedule(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-fee-schedule';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  GetStudentFee(data):Observable<any>
  {
    const url = this.BASE_URL + 'fee-detail-by-student';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  } 
  UpdateStudentFees(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-student-fee';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }




  
  

}
