import { Component, AfterViewInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbPanelChangeEvent, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'; 
import {AuthService} from '../../services/auth.service';
import {DataService } from '../../services/data.service';
import {EncryptionService} from '../../services/encryption.service';
@Component({
  selector: 'ap-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
	name:string;
   users:any;
    role_id:any;
    user_id:any;    
    staff_email:any;    
    staff_fullname:any;  
  	public config: PerfectScrollbarConfigInterface = {};
  	constructor(private modalService: NgbModal, private AuthService:AuthService,private EncryptionService:EncryptionService,private DataService:DataService) 
    {
     this.DataService.getUserState().subscribe(data=>{
      this.users=data.users;
      this.updateuserdata();

    }) 

    let user_id=localStorage.getItem('user_id') || '';
    let role_id=localStorage.getItem('role_id') || '';
    let staff_firstname=localStorage.getItem('staff_firstname') || '' ;
    let staff_lastname=localStorage.getItem('staff_lastname') || '';
    let staff_email=localStorage.getItem('staff_email') || '';
    this.users={user_id:user_id,role_id:role_id,staff_firstname:staff_firstname,staff_lastname:staff_lastname,staff_email:staff_email};
    this.updateuserdata();	
    }

    // This is for Notifications
    notifications: Object[] = [{
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',    
      subject: 'Just see the my new admin!',
      time: '9:30 AM'  
    }, {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',    
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    }, {
      round: 'round-info', 
      icon: 'ti-settings',
      title: 'Settings',    
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    }, {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',    
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }];
    
    // This is for Mymessages
    mymessages: Object[] = [{
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',    
      subject: 'Just see the my admin!',
      time: '9:30 AM'  
    }, {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',    
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    }, {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',    
      subject: 'I am a singer!',
      time: '9:08 AM'
    }, {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',    
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }];
      
    ngAfterViewInit() {
        
        var set = function() {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            var topOffset = 0;
            if (width < 1170) {
                $("#main-wrapper").addClass("mini-sidebar");
            } else {
                $("#main-wrapper").removeClass("mini-sidebar");
            }
        };
        $(window).ready(set);
        $(window).on("resize", set);

        
        $(".search-box a, .search-box .app-search .srh-btn").on('click', function () {
            $(".app-search").toggle(200);
        });
        
        
        $("body").trigger("resize");
    }

    LogOut()
    {
      this.AuthService.LogOut();
    }

     updateuserdata()
    {
        this.role_id=this.EncryptionService.DecryptText(this.users.role_id);
        this.user_id=this.EncryptionService.DecryptText(this.users.user_id);
        this.staff_email=this.EncryptionService.DecryptText(this.users.staff_email);
        this.staff_fullname=this.EncryptionService.DecryptText(this.users.staff_firstname)+" "+this.EncryptionService.DecryptText(this.users.staff_lastname);
        
    }
}
