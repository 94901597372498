import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EncryptionService } from './encryption.service';
import { StaffService } from './staff.service';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { APIEndpoint } from '../appsettings/BaseUrl';


@Injectable()
export class ClassesService {  
  private BASE_URL: string;
  _headers:any;
  constructor(private http:HttpClient, private _router:Router,private EncryptionService:EncryptionService,private StaffService:StaffService) {
    this.BASE_URL = APIEndpoint.BaseUrl;
    this._headers= new HttpHeaders({
    'Client-Service': 'LRL2018SH',
    'Auth-Key': '5ccd7b534b19d30030c6503f3a852d00',
    'Content-Type': 'application/json'
  })
  }
  AddClass(data):Observable<any>{    
    const url = this.BASE_URL + 'add-class';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  }

  UpdateClass(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-class';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  DeleteClass(data):Observable<any>
  {
    const url = this.BASE_URL + 'delete-class';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  GetClassList(data={}):Observable<any>
  {    
    const url = this.BASE_URL + 'class-list';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  }  
  GetClassMaster(data={}):Observable<any>
  { 
   let user_id=localStorage.getItem('user_id');
   user_id=this.EncryptionService.DecryptText(user_id);
   let role_id=localStorage.getItem('role_id');
   role_id=this.EncryptionService.DecryptText(role_id);    
   if(role_id=="3")
   {
    data={teacher_id:user_id};
   }
   const url = this.BASE_URL + 'class-master';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  }  
   SearchClass(data):Observable<any>
  {    
    const url = this.BASE_URL + 'search-class';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  }

  SectionByClass(data):Observable<any>{
  let user_id=localStorage.getItem('user_id');
   user_id=this.EncryptionService.DecryptText(user_id);
   let role_id=localStorage.getItem('role_id');
   role_id=this.EncryptionService.DecryptText(role_id);    
   if(role_id=="3")
   {
    data={teacher_id:user_id,class_id:data.class_id};
   }

    const url = this.BASE_URL + 'class-section-list';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  ClassSectionFilter(data,filter)
    {
      var data1=[];
      for(let i=0;i<filter.length;i++)
      {      
        for(let j=0;j<data.length;j++){

           if(filter[i]==data[j].id)
           {
           data1.push(data[j]);
            break;
           }
      }
    }  
    // data=data.filter(s=>{
    //     for(let i=0;i<data.length;i++)
    //     {
    //       if(filter[i]==s.id)
    //       {
    //         return s;
    //       }
    //     }
    //   });
    return data1;
  }


  CheckClassSection(){
  let user_id=localStorage.getItem('user_id');
   user_id=this.EncryptionService.DecryptText(user_id); 
   this.StaffService.GetClassSectionByTeacher({teacher_id:user_id}).subscribe(res=>{
     if (res.status == 200) {
        localStorage.setItem('class_data', JSON.stringify(res.data.class));
        localStorage.setItem('section_data',JSON.stringify(res.data.section));    
           
        }  
      });
}
 CheckTeacherSection(class_id){
  let user_id=localStorage.getItem('user_id');
   user_id=this.EncryptionService.DecryptText(user_id); 
   this.StaffService.CheckTeacherSection({teacher_id:user_id,class_id:class_id}).subscribe(res=>{
     if (res.status == 200) {
        localStorage.setItem('section_data',JSON.stringify(res.data.section));                
        }  
      });
}
CheckTeacherSubject(class_id,section_id){
  let user_id=localStorage.getItem('user_id');
   user_id=this.EncryptionService.DecryptText(user_id); 
   this.StaffService.CheckTeacherSubject({teacher_id:user_id,class_id:class_id,section_id:section_id}).subscribe(res=>{
     if (res.status == 200) {
        localStorage.setItem('subject_data',JSON.stringify(res.data.subject));   
        }  
      });
}

}
