import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { APIEndpoint } from '../appsettings/BaseUrl';
@Injectable()
export class StudentService {
  
  private BASE_URL: string;
  _headers:any;
  constructor(private http:HttpClient, private _router:Router) {
    this.BASE_URL = APIEndpoint.BaseUrl;
    this._headers= new HttpHeaders({
    'Client-Service': 'LRL2018SH',
    'Auth-Key': '5ccd7b534b19d30030c6503f3a852d00',
    'Content-Type': 'application/json'
  })
  }
  AddStudent(data):Observable<any>{    
    const url = this.BASE_URL + 'add-student-profile';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  }

  UpdateStudent(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-student-profile';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  DeleteStudent(data):Observable<any>
  {
    const url = this.BASE_URL + 'delete-student-profile';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  GetStudentList():Observable<any>
  {    
    const url = this.BASE_URL + 'student-profile-list';
    return this.http.post(url,{},{headers:this._headers}).map(res=>res);
  } 
  SearchStudentList():Observable<any>
  {    
    const url = this.BASE_URL + 'search-student-profile';
    return this.http.post(url,{},{headers:this._headers}).map(res=>res);
  }  
  StudentList(data):Observable<any>
  {
    
    const url = this.BASE_URL + 'student-list';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  StudentListBySubject(data):Observable<any>
  {
    
    const url = this.BASE_URL + 'student-list-bysubject';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  
  PromoteStudentList(data):Observable<any>
  {
    
    const url = this.BASE_URL + 'promote-student-list';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }


  StudentDetails(data):Observable<any>
  {
     const url = this.BASE_URL + 'student-profile';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  AddAttendance(data):Observable<any>
  {
     const url = this.BASE_URL + 'add-attendance';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  AttendanceSummary(data):Observable<any>
  {
     const url = this.BASE_URL + 'attendance-summary';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  AddRemarks(data):Observable<any>
  {
     const url = this.BASE_URL + 'add-remarks';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  GetRemarksList(data):Observable<any>
  {
     const url = this.BASE_URL + 'remarks-list';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  UpdateRemark(data):Observable<any>
  {
     const url = this.BASE_URL + 'update-remarks';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  DeleteRemark(data):Observable<any>
  {
     const url = this.BASE_URL + 'delete-remarks';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  SearchRemark(data):Observable<any>
  {
     const url = this.BASE_URL + 'search-remarks';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

GetHomeWorkData(data):Observable<any>
  {
     const url = this.BASE_URL + 'homework-list';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  DeleteHomeWork(data):Observable<any>
  {
     const url = this.BASE_URL + 'delete-homework';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  AddStudentMarks(data):Observable<any>
  {
     const url = this.BASE_URL + 'add-student-marks';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  GetStudentMarks(data):Observable<any>
  {
     const url = this.BASE_URL + 'get-student-result';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  DeleteStudentMarks(data):Observable<any>
  {
     const url = this.BASE_URL + 'delete-student-marks';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  GetStudentResult(data):Observable<any>
  {
     const url = this.BASE_URL + 'student-result';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  updateExamRemarks(data):Observable<any>
  {
     const url = this.BASE_URL + 'update-exam-remarks';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  GetStudentAttendance(data):Observable<any>
  {
     const url = this.BASE_URL + 'attendance-list';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  StudentAttendance(data):Observable<any>
  {
     const url = this.BASE_URL + 'get-attendance-list';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }    
  


  ClassWiseStudent(id):Observable<any>
  {
    const url = this.BASE_URL + 'student-list';
     return this.http.post(url,{class_id:id},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
   ClassWiseStudentBus(id):Observable<any>
  {
    const url = this.BASE_URL + 'student-list-bus';
     return this.http.post(url,{class_id:id},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }


  GetCityList(id)
  {
     const url = this.BASE_URL + 'city-list';
     return this.http.post(url,{state_id:id},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    }); 
  }
  GetStateList()
  {
     const url = this.BASE_URL + 'state-list';
     return this.http.post(url,{},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    }); 
  }


  GetAutoGeneratedScholor()
  {
    const url = this.BASE_URL + 'auto-enroll';
     return this.http.post(url,{},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    }); 
  }

 BlockStudent(data):Observable<any>
  {
     const url = this.BASE_URL + 'block-student';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }  
updateRoll(data):Observable<any>
  {
     const url = this.BASE_URL + 'update-student-roll';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }  
  
  
}
