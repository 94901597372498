import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { APIEndpoint } from '../appsettings/BaseUrl';

@Injectable()
export class TeacherService {
  
  private BASE_URL: string;
  _headers:any;
  constructor(private http:HttpClient, private _router:Router) {
    this.BASE_URL = APIEndpoint.BaseUrl;
    this._headers= new HttpHeaders({
    'Client-Service': 'LRL2018SH',
    'Auth-Key': '5ccd7b534b19d30030c6503f3a852d00',
    'Content-Type': 'application/json'
  })


  }

  AddStaff(data):Observable<any>{    
    const url = this.BASE_URL + 'add-teacher';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  }

  AddStaffRole(data):Observable<any>
  {
    const url = this.BASE_URL + 'add-teacher-role';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  UpdateStaffRole(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-teacher-role';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  DeleteStaffRole(data):Observable<any>
  {
    const url = this.BASE_URL + 'delete-teacher-role';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  GetStaffRole():Observable<any>
  {    
    const url = this.BASE_URL + 'staff-role-list';
    return this.http.post(url,{},{headers:this._headers}).map(res=>res);
  }
  
  SearchStaffRole(data):Observable<any>
  {    
    const url = this.BASE_URL + 'search-teacher-role';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  }

  SearchStaff(data):Observable<any>
  {    
    const url = this.BASE_URL + 'search-teacher';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res);
  }

  GetStaff():Observable<any>
  {    
    const url = this.BASE_URL + 'teacher-list';
    return this.http.post(url,{},{headers:this._headers}).map(res=>res);
  }
  UpdateStaff(data):Observable<any>
  {
    const url = this.BASE_URL + 'update-teacher';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  DeleteStaff(data):Observable<any>
  {    
    const url = this.BASE_URL + 'delete-teacher';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }


  AllocateTeacher(data):Observable<any>
  {    
    const url = this.BASE_URL + 'class-teacher-allotment';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  } 
  UnAllocateTeacher(data):Observable<any>
  {    
    const url = this.BASE_URL + 'class-teacher-unallotment';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

  GetAllTeachers():Observable<any>
  {    
    const url = this.BASE_URL + 'teacher-list';
     return this.http.post(url,{},{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }

   GetClassSubjectTeacher(data):Observable<any>
  {    
    const url = this.BASE_URL + 'class-subject-teacher';
     return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  AddClassSubjectTeacher(data):Observable<any>
  {
    const url = this.BASE_URL + 'add-class-subject-teacher';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }
  RemoveClassSubjectTeacher(data):Observable<any>
  {
    const url = this.BASE_URL + 'remove-class-subject-teacher';
    return this.http.post(url,data,{headers:this._headers}).map(res=>res).catch((e:any)=>{
      return Observable.throw(e);
    });
  }



}
